import React from 'react'

function ContactUs() {
  
  return (
    <div style={{ background: '#000000' }} id='contactus'>
      <div className='s-heading'>
        
        <div style={{ width: '100%' }}>
            <iframe
                width="100%"
                height="600"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                src="https://www.google.com/maps/d/embed?mid=1phzZwera1aRUpCpEFfAey9HdhmJuzPc&ehbc=2E312F&z=14&noprof=1"
                allowFullScreen
            >
            </iframe>
        </div>
      </div>
    </div>
  )
}

export default ContactUs