import React from 'react';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import OurStores from './components/OurStores';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import WhatWeDo from './components/WhatWeDo';
import ContactUs from './components/ContactUs';

function App() {
  return (
    <div className='App'>
      <Header/>
      <OurStores/>
      <hr className='w-75 mx-auto my-5'/>
      <AboutUs/>
      <hr className='w-75 mx-auto my-5'/>
      <WhatWeDo/>
      <hr className='w-75 mx-auto my-5'/>
      <ContactUs/>
      <hr style={{marginBottom: '-30px'}} className='mt-5 text-white'/>
      <br/>
      <br/>
      <p style={{background: '#000000', padding: '20px' }} className='text-center text-white'>© Copyright. Techmart 2024</p>
    </div>
  );
}

export default App;
library.add(fab, fas, far);
