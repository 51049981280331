import React from 'react';
import featureimage from '../images/3DStore.png';

function AboutUs() {
  return (
    <div id='aboutus'>
      <div className='row mx-auto'>
        <div className='col-sm-6'>
        <div className='mx-auto text-center'>
            <img className='w-50 mx-auto jus' src={featureimage} alt='About Us Image'/>
        </div>
        </div>
        <div className='col-sm-6'>
        <div className='features-text'>
          <br/>
          <br/>
          <br/>
          <br/>
            <h2>About Us</h2>
            <br/>
            <h5>Empowering Your Tech Lifestyle, One Device at a Time.</h5>
        </div>
        </div>
      </div>
        
        
    </div>
  )
}

export default AboutUs