import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { Link } from 'react-scroll';
import logo from '../images/qTechmartLogo.png';

function Header() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 768;
  return (
    <div id='main'>
      <Navbar />
      <div className='name'>
        <div style={{ display: 'flex', justifyContent: 'center', paddingLeft: '35px', marginTop: isMobile? '-100px': 0}}>
          <img
            style={{
              width: isMobile ? '370px' : '100%',
              marginBottom: isMobile ? 0 : '-140px',
              marginTop: isMobile ? '-150px' : '-50px',
            }}
            src={logo}
            alt='logo'
            smooth={true}
            duration={2000}
          />
        </div>



        <p className='details' style={{ color: 'white', marginTop: isMobile ? '-100px' : '-2px', marginLeft: isMobile ? '30px' : 0 }}>We ensure to provide you with the necessary information for you to reach us, and to locate our stores</p>
        <div className='header-btns'>
          <Link to='ourstores' className='text-white text-decoration-none' smooth={true} duration={100}>
            <a className='cv-btn text-decoration-none'><b>Our Stores</b></a>
          </Link>
          <Link to='contactus' className='text-white text-decoration-none' smooth={true} duration={100}>
            <a className='cv-btn1 text-decoration-none'><b>Find Us</b></a>
          </Link>
        </div>
      </div>
      <br/>
      <br />
      <Link to='ourstores' className='text-white text-decoration-none' smooth={true} duration={100}>
        <div className='arrow'></div>
      </Link>
    </div>
  )
}

export default Header