import React from 'react';
import { Link } from 'react-scroll';
import logo from '../images/TechmartLogo1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Navbar() {
  return (
    <div>
      <nav>
        <Link to='main' className='logo'>
          <img style={{ width: '50%' }} src={logo} alt='logo' smooth={true} duration={2000} />
        </Link>
        <input className='menu-btn' type='checkbox' id='menu-btn' />
        <label className='menu-icon' htmlFor='menu-btn'>
          <span className='nav-icon'></span>
        </label>
        <ul className='menu'>
          <li><Link to='main' className='text-white text-decoration-none' smooth={true} duration={100}><b>Home</b></Link></li>
          <li><Link to='ourstores' className='text-white text-decoration-none' smooth={true} duration={100}><b>Our Stores</b></Link></li>
          <li><Link to='aboutus' className='text-white text-decoration-none' smooth={true} duration={100}><b>About Us</b></Link></li>
          <li><Link to='whatwedo' className='text-white text-decoration-none' smooth={true} duration={100}><b>What We Do</b></Link></li>
          <li><Link to='contactus' className='text-white text-decoration-none' smooth={true} duration={100}><b>Find Us</b></Link></li>
        </ul>
        <div className='flex'>
          <a href="https://www.facebook.com/TechMart10/" target='_blank' className='text-decoration-none'><FontAwesomeIcon style={{ color: 'white', margin: '5px' }} icon="fa-brands fa-facebook" /></a>
          <a href="https://www.instagram.com/techmart_electronics_store/" target='_blank' className='text-decoration-none'><FontAwesomeIcon style={{ color: 'white', margin: '5px' }} icon="fa-brands fa-instagram" /></a>
          <a to='#' className='text-decoration-none'><FontAwesomeIcon style={{ color: 'white', margin: '5px' }} icon="fa-brands fa-whatsapp" /></a>
        </div>

      </nav>
    </div>
  )
}

export default Navbar;
