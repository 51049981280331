import React from 'react';
import Box from './Box';
import Store1 from '../images/Store1.png'
import Store2 from '../images/Store2.png'
import Store3 from '../images/Store3.png'
import Store4 from '../images/Store4.png'

function OurStores() {
  return (
    <div id='ourstores'>
        <div className='s-heading'>
            <h1>Our Stores</h1>
            <p>Here are our stores on the islands. Please see below the locations, opening hours <br/>and contact information for the stores.</p>
        </div>
        <div className='b-container'>
            <Box image={Store3} alte='Orion Mall Store 101' location='First Floor, Orion Mall. Room 101. IT Department' openinghoursWD='Weekdays: 9am - 4.30pm' openinghoursS='Saturday: 9am - 1pm' openinghoursSu='Sunday: Closed' mobile='+248 4325261' style={{height: '200px'}}/>
            <Box image={Store2} alte='image1' location='First Floor, Orion Mall. Room 111' openinghoursWD='Weekdays: 9am - 4.30pm' openinghoursS='Saturday: 9am - 1pm' openinghoursSu='Sunday: Closed' mobile='+248 4322078'/>
            <Box image={Store1} alte='image1' location='Ground floor, Orion Mall' openinghoursWD='Weekdays: 9am - 4.30pm' openinghoursS='Saturday: 9am - 1pm' openinghoursSu='Sunday: Closed' mobile='+248 4321567' style={{height: '200px'}}/>
            <Box image={Store4} alte='image1' location='Ground Floor, Sound and Vision Building' openinghoursWD='Weekdays: 9am - 4.30pm' openinghoursS='Saturday: 9am - 1pm' openinghoursSu='Sunday: Closed' mobile='+248 4323611'/>
        </div>
    </div>
  )
}

export default OurStores