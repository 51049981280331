import React from 'react';
import StoreIMG from '../images/STORE3D2.png';
import GiftCard from '../images/GiftCard.png';
import Customers from '../images/3DStore.png'

function WhatWeDo() {
  return (
    <div id='whatwedo'>
        <div className='s-heading' style={{padding: { xs: '5px', sm: '100px' }}}>
            <h1>What We Do</h1>
            <br/>
            <div className='mx-5'>
            <p style={{text: 'white', fontSize: '16pt'}}>At TECHMART we prioritize customer satisfaction by offering a wide range of high-quality consumer electronics, ensuring a pleasant shopping environment, and delivering excellent customer service through our knowledgeable and approachable staff who can guide customers through our diverse product inventory that caters to various customer preferences, from the latest gadgets and cutting-edge technology to reliable and budget-friendly options.</p>
            <br/>
            <p style={{text: 'white', fontSize: '16pt'}}>We are consistently introducing and showcasing the latest products and advancements in the consumer electronics industry while striving to offer competitive and transparent pricing, ensuring customers feel they receive value for their investment in consumer electronics. We also offer after-sales support services, including warranties, technical assistance, and repair services, to build long-term customer loyalty and trust. </p>
            </div>
            
        </div>
        <br/>
        {/* <div className='row'>
            <div className='col-sm-4'>
                <div className='mx-auto text-center'>
                    <img className='mx-auto text-center' src={StoreIMG} alt='3D Store' width={260}/>
                    <p className='lead text-white'>Service 1</p>
                    <p className='text-white'>A brief explanation about Service 2</p>
                </div>
            </div>
            <div className='col-sm-4'>
                <div className='mx-auto text-center'>
                    <img className='mx-auto text-center' src={GiftCard} alt='Gift Card' width={300}/>
                    <p className='lead text-white'>Service 2</p>
                    <p className='text-white'>A brief explanation about Service 2</p>
                </div>
            </div>
            <div className='col-sm-4'>
                <div className='mx-auto text-center'>
                    <img className='mx-auto text-center' src={Customers} alt='3D Store' width={200}/>
                    <p className='lead text-white'>Service 3</p>
                    <p className='text-white'>A brief explanation about Service 3</p>
                </div>
            </div>
        </div> */}
    </div>
  )
}

export default WhatWeDo