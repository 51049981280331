import React from 'react'

function Box(props) {
  return (
    <div className='s-box'>
      <div className='s-b-img'>
        <img src={props.image} alt={props.alte}/>
      </div>
      <div className='px-4 pt-2'>
        <p><b>Location:</b> {props.location}</p>
        <p><b>Opening Hours:</b></p> 
        <div style={{marginLeft: 10}}>
        <p>{props.openinghoursWD}</p>
        <p>{props.openinghoursS}</p>
        <p>{props.openinghoursSu}</p>
        </div>
        <p><b>Mobile:</b> {props.mobile}</p>
      </div>
    </div>
  )
}

export default Box